import { Link, withRouter } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { Redirect, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Tooltip, message } from 'antd';
import { QuestionOutlined } from '@ant-design/icons';
import {
    Wrapper,
    Container,
    RightSideMenu,
    BackButtonContainer,
    BackButtonText,
    BackButton,
    Header,
    TitleContainer,
    DayTitleBox,
    PlanStatusBox,
    NextDayArrow,
    PreviousDayArrow,
    StatusBoxSpinner,
    SheetContainer,
    VersionDropdownContainer,
    SideMenuContainer,
    FilterSelectInputTwo,
    MenuItem
} from './DetailedDailySchedule.style';
import arrowBackIcon from '../../assets/images/icons/arrow-back.svg';
import caretLeftIcon from '../../assets/images/icons/caret-left.svg';
import caretRightIcon from '../../assets/images/icons/caret-right.svg';
import BHANSALogo from '../../assets/images/bhansa-logo-notext-inverted.svg';
import useDetailedDailySchedule from './hooks/useDetailedDailySchedule';
import DailySheet from './dailySheet/DailySheet';
import saveIcon from '../../assets/images/icons/awesome-save.svg';
import saveAsNewIcon from '../../assets/images/icons/awesome-save-plus.svg';
import confirmIcon from '../../assets/images/icons/confirm-icon.svg';
import cancelIcon from '../../assets/images/icons/material-cancel.svg';
import revertIcon from '../../assets/images/icons/awesome-undo.svg';
import sendIcon from '../../assets/images/icons/send-icon.svg';
import confirmModal from '../../core/components/modals/ConfirmModal';
import { useAuthStateValue } from '../../context/AuthContext';
import planStatusTypes from '../../util/planStatusTypes.json';
import { getAllDailySchedulesByMonth, getDailyScheduleContent } from '../../services/dailyPlan.service';
import { timeStringToHoursCount } from '../../util/util';

const DetailedDailySchedule = (props) => {
  const [modifiedData, setModifiedData] = useState();
  const { year, month, day, version, mode } = useParams();
  const { loggedUser } = useAuthStateValue();
  const { t } = useTranslation();
  const {
    loading,
    getDailyPlanContent,
    dataSource,
    versions,
    getDailyPlanVersions,
    saveChangesToCurrentVersion,
    saveChangesToNewVersion,
    sendToReview,
    approveSchedule,
    rejectSchedule,
    interval,
    setNextDayLatestVersion,
    setPreviousDayLatestVersion,
    nextDayLatestVersion,
    previousDayLatestVersion
  } = useDetailedDailySchedule({ year, month, day, version, loggedUserId: loggedUser?.id, modifiedData });

  // const locationId = loggedUser?.locationsData.find((el) => el?.value === location)?.id;
  const dayInWeek = new Date(year, month - 1, day).getDay();

  const versionStatusType = planStatusTypes?.daily?.find((statusType) => statusType?.key === versions?.find((el) => el?.value === parseInt(version, 10))?.status);

  let latestVersion;
  versions?.forEach((el) => {
    if (!latestVersion) {
      latestVersion = el;
    } else if (el?.key > latestVersion?.key) latestVersion = el;
  });

  const latestVersionIsSelected = parseInt(version, 10) === latestVersion?.key;

  const paramsAreValid = (/^\d+$/.test(year))
      && (/^\d+$/.test(month))
      && parseInt(month, 10) < 13
      && year.startsWith('2') && year.length < 5
      && parseInt(day, 10) < 32 && parseInt(day, 10) > 0
      && parseInt(version, 10)
      && (mode === 'view' || (mode === 'edit')
      // && (loggedUser?.localPermissions?.[locationId]?.dailyPlan === 'APPROVE_AND_ACTIVATE' || loggedUser?.localPermissions?.[locationId]?.dailyPlan === 'GENERATE_AND_MODIFY')
      );

  const todayInt = parseInt(day, 10);
  const currentMonthInt = parseInt(month, 10);
  const currentYearInt = parseInt(year, 10);

  if (!paramsAreValid) {
    return <Redirect push to="/404" />;
  }

  const noVersionsGenerated = !!versions && versions?.length === 0;

  const findNewestVersion = async (versionsArray) => {
    let newestVersionNumber = 0;
    await versionsArray?.forEach((el) => {
      if (parseInt(el?.value, 10) > newestVersionNumber) newestVersionNumber = parseInt(el?.value, 10);
    });

    return newestVersionNumber;
  }

  const saveHandler = async () => {
    const currentVersionIdentificator = versions?.find((el) => el?.value === parseInt(version, 10))?.identificator;
    const res = await getAllDailySchedulesByMonth({ year, month });
    const thisDayVersions = res?.data?.find((el) => el?.day === parseInt(day, 10))?.versions?.map((el) => {
      return {
        key: el?.version,
        value: el?.version,
        label: t('dailySchedule.version') + ' ' + el?.version,
        status: el?.status,
        versionId: el?.id,
        identificator: el?.identificator
      }
    });

    const newerVersionIsPresent = !!thisDayVersions?.find((el) => (parseInt(el?.value, 10) > parseInt(version, 10))) || thisDayVersions?.find((el) => parseInt(el?.value, 10) === parseInt(version, 10))?.identificator !== currentVersionIdentificator;

    if (newerVersionIsPresent) {
      const newestVersionNumber = await findNewestVersion(thisDayVersions);
      const res = await getDailyScheduleContent({ year, month, day, version: newestVersionNumber });
      // Check for conflicts
      const conflicts = [];
      const acceptableChanges = [];
      const conflictsMessageList = [];
      modifiedData?.[modifiedData?.length - 1]?.forEach((el) => {
        const controllerDataInNewVersion = res?.data?.controllers?.find((controller) => controller?.id === el?.controllerId);
        const overlappingInNewVersion = controllerDataInNewVersion?.obligations?.find((obl) => ((timeStringToHoursCount(el?.timeFrom, false) >= timeStringToHoursCount(obl?.timeFrom, false)) || (timeStringToHoursCount(el?.timeFrom, false) <= timeStringToHoursCount(obl?.timeTo, false))));
        if (overlappingInNewVersion) {
          const sectorName = loggedUser?.sectors?.find((sector) => sector?.id === el?.sectorId)?.shortName;
          const sectorRole = loggedUser?.sectorRoles?.find((sectorRole) => sectorRole?.id === el?.sectorRoleId)?.shortName;
          conflicts.push(el);
          conflictsMessageList.push(<li>{controllerDataInNewVersion?.firstName + ' ' + controllerDataInNewVersion?.lastName + ' ' + el?.timeFrom + ' - ' + el?.timeTo + ' (' + sectorName + ' ' + sectorRole + ')'}</li>);
        } else acceptableChanges.push(el);
      });
      confirmModal({
        centered: true,
        icon: <QuestionOutlined />,
        title: t('dailySchedule.newerVersionIsPresent'),
        content: t('dailySchedule.newerVersionPresentText'),
        okText: t('dailySchedule.load'),
        cancelText: t('dailySchedule.cancel'),
        style: { whiteSpace: 'pre-line' },
        onOk: () => {
          if (conflicts?.length > 0) {
            confirmModal({
              centered: true,
              icon: <QuestionOutlined />,
              title: t('dailySchedule.newVersionConflicts'),
              content: (
                <>
                  {t('dailySchedule.newVersionConflictsText')}
                  <br />
                  <br />
                  <ul style={{ fontSize: '11px' }}>
                    {conflictsMessageList}
                  </ul>
                </>
              ),
              okText: t('buttons.continue'),
              cancelText: t('buttons.cancel'),
              onOk: () => {
                if (newestVersionNumber === parseInt(version, 10)) {
                  getDailyPlanContent();
                  getDailyPlanVersions();
                } else {
                  props?.history?.push({ pathname: '/daily/' + year + '/' + month + '/' + day + '/' + newestVersionNumber + '/edit', state: { changes: acceptableChanges } });
                }
              }
            })
          } else if (newestVersionNumber === parseInt(version, 10)) {
            getDailyPlanContent();
            getDailyPlanVersions();
          } else {
            props?.history?.push({ pathname: '/daily/' + year + '/' + month + '/' + day + '/' + newestVersionNumber + '/edit', state: { changes: acceptableChanges } });
          }
        }
      });
    } else {
      confirmModal({
        centered: true,
        icon: <QuestionOutlined />,
        title: t('dailySchedule.saveChanges'),
        content: t('dailySchedule.saveChangesConfirm'),
        okText: t('buttons.yes'),
        cancelText: t('buttons.no'),
        onOk: async (closeFn) => {
          closeFn();
          await saveChangesToCurrentVersion();
          setModifiedData(null);
          await getDailyPlanVersions();
          await getDailyPlanContent();
          props.history.push({ pathname: `/daily/${year}/${month}/${day}/${parseInt(version, 10)}/${mode}`, state: { changes: [] } });
        }
      });
    }
  }

  const saveAsNewVersionHandler = async () => {
    const currentVersionIdentificator = versions?.find((el) => el?.value === parseInt(version, 10))?.identificator;
    const res = await getAllDailySchedulesByMonth({ year, month });
    const thisDayVersions = res?.data?.find((el) => el?.day === parseInt(day, 10))?.versions?.map((el) => {
      return {
        key: el?.version,
        value: el?.version,
        label: t('dailySchedule.version') + ' ' + el?.version,
        status: el?.status,
        versionId: el?.id,
        identificator: el?.identificator
      }
    });
    const newerVersionIsPresent = !!thisDayVersions?.find((el) => (parseInt(el?.value, 10) > parseInt(version, 10))) || thisDayVersions?.find((el) => parseInt(el?.value, 10) === parseInt(version, 10))?.identificator !== currentVersionIdentificator;

    if (newerVersionIsPresent) {
      const newestVersionNumber = await findNewestVersion(thisDayVersions);
      const res = await getDailyScheduleContent({ year, month, day, version: newestVersionNumber });
      // Check for conflicts
      const conflicts = [];
      const acceptableChanges = [];
      const conflictsMessageList = [];
      modifiedData?.[modifiedData?.length - 1]?.forEach((el) => {
        const controllerDataInNewVersion = res?.data?.controllers?.find((controller) => controller?.id === el?.controllerId);
        const overlappingInNewVersion = controllerDataInNewVersion?.obligations?.find((obl) => ((timeStringToHoursCount(el?.timeFrom, false) >= timeStringToHoursCount(obl?.timeFrom, false)) || (timeStringToHoursCount(el?.timeFrom, false) <= timeStringToHoursCount(obl?.timeTo, false))));
        if (overlappingInNewVersion) {
          const sectorName = loggedUser?.sectors?.find((sector) => sector?.id === el?.sectorId)?.shortName;
          const sectorRole = loggedUser?.sectorRoles?.find((sectorRole) => sectorRole?.id === el?.sectorRoleId)?.shortName;
          conflicts.push(el);
          conflictsMessageList.push(<li>{controllerDataInNewVersion?.firstName + ' ' + controllerDataInNewVersion?.lastName + ' ' + el?.timeFrom + ' - ' + el?.timeTo + ' (' + sectorName + ' ' + sectorRole + ')'}</li>);
        } else acceptableChanges.push(el);
      });
      confirmModal({
        centered: true,
        icon: <QuestionOutlined />,
        title: t('dailySchedule.newerVersionIsPresent'),
        content: t('dailySchedule.newerVersionPresentText'),
        okText: t('dailySchedule.load'),
        cancelText: t('dailySchedule.cancel'),
        style: { whiteSpace: 'pre-line' },
        onOk: () => {
          if (conflicts?.length > 0) {
            confirmModal({
              centered: true,
              icon: <QuestionOutlined />,
              title: t('dailySchedule.newVersionConflicts'),
              content: (
                <>
                  {t('dailySchedule.newVersionConflictsText')}
                  <br />
                  <br />
                  <ul style={{ fontSize: '11px' }}>
                    {conflictsMessageList}
                  </ul>
                </>
              ),
              okText: t('buttons.continue'),
              cancelText: t('buttons.cancel'),
              onOk: () => {
                if (newestVersionNumber === parseInt(version, 10)) {
                  getDailyPlanVersions();
                  getDailyPlanContent();
                } else {
                  props?.history?.push({ pathname: '/daily/' + year + '/' + month + '/' + day + '/' + newestVersionNumber + '/edit', state: { changes: acceptableChanges } });
                }
              }
            })
          } else if (newestVersionNumber === parseInt(version, 10)) {
            getDailyPlanVersions();
            getDailyPlanContent();
          } else {
            props?.history?.push({ pathname: '/daily/' + year + '/' + month + '/' + day + '/' + newestVersionNumber + '/edit', state: { changes: acceptableChanges } });
          }
        }
      });
    } else {
      confirmModal({
        centered: true,
        icon: <QuestionOutlined />,
        title: t('dailySchedule.saveChangesAsNewVersion'),
        content: t('dailySchedule.saveChangesAsNewVersionConfirm'),
        okText: t('buttons.yes'),
        cancelText: t('buttons.no'),
        onOk: async (closeFn) => {
          const goToNewVersion = () => {
            props.history.push({ pathname: `/daily/${year}/${month}/${day}/${parseInt(version, 10) + 1}/${mode}`, state: { changes: [] } });
          }
          closeFn();
          await saveChangesToNewVersion(goToNewVersion);
        }
      });
    }
  }

  const sendToReviewHandler = () => {
    confirmModal({
      centered: true,
      icon: <QuestionOutlined />,
      title: t('dailySchedule.sendForReview'),
      content: t('dailySchedule.sendForReviewConfirm'),
      okText: t('buttons.yes'),
      cancelText: t('buttons.no'),
      onOk: async (closeFn) => {
        closeFn();
        await sendToReview();
        await getDailyPlanVersions();
        await getDailyPlanContent();
      }
    });
  };

  const approveHandler = () => {
    confirmModal({
      centered: true,
      icon: <QuestionOutlined />,
      title: t('dailySchedule.approveSchedule'),
      content: t('dailySchedule.approveScheduleConfirm'),
      okText: t('buttons.yes'),
      cancelText: t('buttons.no'),
      onOk: async (closeFn) => {
        closeFn();
        await approveSchedule();
        await getDailyPlanVersions();
        await getDailyPlanContent();
      }
    });
  };

  const rejectHandler = () => {
    confirmModal({
      centered: true,
      icon: <QuestionOutlined />,
      title: t('dailySchedule.rejectSchedule'),
      content: t('dailySchedule.rejectScheduleConfirm'),
      okText: t('buttons.yes'),
      cancelText: t('buttons.no'),
      onOk: async (closeFn) => {
        closeFn();
        await rejectSchedule();
        await getDailyPlanVersions();
        await getDailyPlanContent();
      }
    });
  };

  let dailyPlanPermissions;
  const loggedUserAtcu = loggedUser?.extendUser?.locationIdentificator;
  const loggedUserAtcuId = loggedUser?.locationsData?.find((el) => el?.value === loggedUserAtcu)?.id;
  if (loggedUserAtcuId) {
    dailyPlanPermissions = loggedUser?.localPermissions?.[loggedUserAtcuId]?.dailyPlan;
  }

  const menuItems = [
    {
      key: 'save',
      icon: saveIcon,
      title: t('dailySchedule.saveChanges'),
      onClick: () => {
        if (modifiedData?.[modifiedData?.length - 1]?.find((el) => !el?.sectorId || !el?.sectorRoleId)) {
          message.error(t('dailySchedule.noEmptyObligations'));
        } else saveHandler();
      },
      disabled: !modifiedData || modifiedData?.length === 0,
      visibleInModes: ['edit']
    },
    {
      key: 'saveAsNew',
      icon: saveAsNewIcon,
      title: t('dailySchedule.saveChangesAsNewVersion'),
            onClick: () => {
              if (modifiedData?.[modifiedData?.length - 1]?.find((el) => !el?.sectorId || !el?.sectorRoleId)) {
                message.error(t('dailySchedule.noEmptyObligations'));
              } else saveAsNewVersionHandler();
            },
      disabled: !modifiedData || modifiedData?.length === 0,
      visibleInModes: ['edit']
    },
    dailyPlanPermissions === 'APPROVE_AND_ACTIVATE' && {
      key: 'approve',
      icon: confirmIcon,
      title: t('dailySchedule.approveSchedule'),
      onClick: approveHandler,
      disabled: versionStatusType?.key !== 'PENDING_APPROVAL',
      visibleInModes: ['view', 'edit']
    },
    dailyPlanPermissions === 'APPROVE_AND_ACTIVATE' && {
      key: 'reject',
      icon: cancelIcon,
      title: t('dailySchedule.rejectSchedule'),
      onClick: rejectHandler,
      disabled: versionStatusType?.key !== 'PENDING_APPROVAL',
      visibleInModes: ['view', 'edit']
    },
    {
      key: 'undo',
      icon: revertIcon,
      title: t('dailySchedule.undoLastChange'),
      onClick: () => {
        setModifiedData((prevState) => {
          const newStateArr = [...prevState];
          newStateArr.pop();
          return newStateArr;
        });
      },
      disabled: !modifiedData || modifiedData?.length === 0,
      visibleInModes: ['edit']
    },
    {
      key: 'send',
      icon: sendIcon,
      title: t('dailySchedule.sendForReview'),
      onClick: sendToReviewHandler,
      disabled: versionStatusType?.key !== 'MANUALLY_CHANGED' || (parseInt(version, 10) !== latestVersion?.key),
      visibleInModes: ['edit', 'view']
    }
  ];

  const goToPreviousDay = () => {
    const previousDayYear = currentMonthInt === 1 && todayInt === 1 ? (currentYearInt - 1) : currentYearInt;
    const previousDayMonth = todayInt !== 1 ? currentMonthInt : (currentMonthInt !== 1 ? (currentMonthInt - 1) : 12);
    const numberOfDaysInPreviousMonth = new Date(previousDayYear, previousDayMonth, 0)?.getDate();
    const previousDayNumber = todayInt === 1 ? numberOfDaysInPreviousMonth : (todayInt - 1);
    props.history.push(`/daily/${previousDayYear}/${previousDayMonth}/${previousDayNumber}/${previousDayLatestVersion ? previousDayLatestVersion?.version : 1}/${mode}`);
  };

  const goToNextDay = () => {
    const numberOfDaysInMonth = new Date(year, month, 0).getDate();
    const nextDayNumber = todayInt < numberOfDaysInMonth ? (todayInt + 1) : (todayInt === numberOfDaysInMonth ? 1 : undefined);
    const nextMonthNumber = todayInt < numberOfDaysInMonth ? currentMonthInt : (currentMonthInt < 12 ? (currentMonthInt + 1) : 1);
    const nextYearNumber = currentMonthInt === 12 && todayInt === 31 ? (currentYearInt + 1) : currentYearInt;

    props.history.push(`/daily/${nextYearNumber}/${nextMonthNumber}/${nextDayNumber}/${nextDayLatestVersion ? nextDayLatestVersion?.version : 1}/${mode}`);
  };

  const versionChangeHandler = (value) => {
    props.history.push(`/daily/${year}/${month}/${day}/${value}/${mode}`);
  };

  useEffect(() => {
    getDailyPlanVersions();
    getDailyPlanContent();
    setModifiedData(null);
    setInterval([]);
    setPreviousDayLatestVersion(null);
    setNextDayLatestVersion(null);
  }, [year, month, day, version]);

  useEffect(() => {
    if (props?.location?.state?.changes?.length > 0) {
      setModifiedData([
        props?.location?.state?.changes
      ]);
    }
  }, [props?.location?.state?.changes])

  return (
    <Wrapper>
      <Container>
        <Header>
          <BackButtonContainer>
            <Link to="/dailyPlan/">
              <BackButton><ReactSVG src={arrowBackIcon} /></BackButton>
            </Link>
            <BackButtonText>
              {t('main.dailyPlan')}
            </BackButtonText>
          </BackButtonContainer>
          <TitleContainer>
            <DayTitleBox>
              <PreviousDayArrow onClick={goToPreviousDay}>
                <ReactSVG src={caretLeftIcon} />
              </PreviousDayArrow>
              {t('daysInWeek.' + dayInWeek) + ', ' + day + '. ' + t('monthlyPlan.' + month) + ' ' + year + '.'}
              <NextDayArrow onClick={goToNextDay}>
                <ReactSVG src={caretRightIcon} />
              </NextDayArrow>
            </DayTitleBox>
            <PlanStatusBox style={{ backgroundColor: versionStatusType?.headerColor ? versionStatusType?.headerColor : '#8f8f8f' }}>
              {loading ? <StatusBoxSpinner /> : (versionStatusType?.title?.sr ? versionStatusType?.title?.sr.toUpperCase() : (noVersionsGenerated ? ('NIJE GENERISAN') : t('dailySchedule.versionDoesntExist').toUpperCase()))}
            </PlanStatusBox>
          </TitleContainer>
          <VersionDropdownContainer>
            {versions?.length > 0 && (
              <FilterSelectInputTwo
                value={versions?.find((el) => el?.key === parseInt(version, 10))}
                options={versions}
                onChange={versionChangeHandler}
                disabled={mode === 'edit'}
              />
            )}
          </VersionDropdownContainer>
        </Header>
        <SheetContainer>
          <DailySheet
            dataSource={dataSource}
            version={version}
            year={year}
            loading={loading}
            month={month}
            day={day}
            modifiedData={modifiedData}
            setModifiedData={setModifiedData}
            notGenerated={noVersionsGenerated}
            nonExistingVersion={!versionStatusType}
            latestVersionIsSelected={latestVersionIsSelected}
            interval={interval}
            editModeOn={mode === 'edit'}
          />
        </SheetContainer>
      </Container>
      <RightSideMenu>
        <ReactSVG width={82} src={BHANSALogo} />
        <SideMenuContainer>
          {menuItems.map((menuItem, index) => {
            if (menuItem?.visibleInModes?.includes(mode)) {
              return (
                <Tooltip key={'menu-item-' + index} placement="left" title={menuItem.title}>
                  <MenuItem className={menuItem?.disabled ? 'disabled' : ''} onClick={menuItem?.disabled ? null : menuItem?.onClick} key={menuItem.key}>
                    <ReactSVG src={menuItem.icon} />
                  </MenuItem>
                </Tooltip>
              )
            }
          })}
        </SideMenuContainer>
      </RightSideMenu>
    </Wrapper>
  );
};

export default withRouter(DetailedDailySchedule);
