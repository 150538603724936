import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import {
  getDailyScheduleContent,
  getAllDailySchedulesByMonth,
  saveChanges,
  saveChangesAsNewVersion,
  sendScheduleToReview,
  approveDailySchedule,
  rejectDailySchedule
} from '../../../services/dailyPlan.service';

const useDetailedDailySchedule = (props) => {
  const { year, month, day, version, loggedUserId, modifiedData } = props;
  const { t } = useTranslation();

  const [loading, setLoading] = useState();
  const [dataSource, setDataSource] = useState();
  const [versions, setVersions] = useState(null);
  const [interval, setInterval] = useState([]);
  const [previousDayLatestVersion, setPreviousDayLatestVersion] = useState();
  const [nextDayLatestVersion, setNextDayLatestVersion] = useState();
  const [nextDayLatestActiveVersion, setNextDayLatestActiveVersion] = useState();

  const currentVersionScheduleId = versions?.find((el) => el?.value === parseInt(version, 10))?.versionId;

  const getDailyPlanVersions = async () => {
    try {
      setLoading(true);
      const res = await getAllDailySchedulesByMonth({ year, month });
      const thisDayVersions = res?.data?.find((el) => el?.day === parseInt(day, 10))?.versions?.map((el) => {
        return {
          key: el?.version,
          value: el?.version,
          label: t('dailySchedule.version') + ' ' + el?.version,
          status: el?.status,
          versionId: el?.id,
          identificator: el?.identificator
        }
      });
      setVersions(thisDayVersions);
      const numberOfDaysInMonth = new Date(year, month, 0)?.getDate();
      if (parseInt(day, 10) !== 1 && parseInt(day, 10) !== numberOfDaysInMonth) {
        const previousDayVersions = res?.data?.find((el) => el?.day === (parseInt(day, 10) - 1))?.versions;
        const nextDayVersions = res?.data?.find((el) => el?.day === (parseInt(day, 10) + 1))?.versions;
        setPreviousDayLatestVersion(previousDayVersions?.[previousDayVersions?.length - 1]);
        setNextDayLatestVersion(nextDayVersions?.[nextDayVersions?.length - 1]);
        setNextDayLatestActiveVersion(nextDayVersions?.findLast((el) => el?.status === 'APPROVED_AND_ACTIVE'))
      } else if (parseInt(day, 10) === 1) {
        const previousMonth = parseInt(month, 10) !== 1 ? (parseInt(month, 10) - 1) : 12;
        const previousMonthYear = previousMonth !== 12 ? parseInt(year, 10) : (parseInt(year, 10) - 1);
        const numberOfDaysInPreviousMonth = new Date(previousMonthYear, previousMonth, 0)?.getDate();
        const previousMonthRes = await getAllDailySchedulesByMonth({ year: previousMonthYear, month: previousMonth });
        const previousDayVersions = previousMonthRes?.data?.find((el) => el?.day === numberOfDaysInPreviousMonth)?.versions;
        const nextDayVersions = res?.data?.find((el) => el?.day === (parseInt(day, 10) + 1))?.versions;
        setPreviousDayLatestVersion(previousDayVersions?.[previousDayVersions?.length - 1]);
        setNextDayLatestVersion(nextDayVersions?.[nextDayVersions?.length - 1]);
      } else if (parseInt(day, 10) === numberOfDaysInMonth) {
        const nextMonth = parseInt(month, 10) !== 12 ? (parseInt(month, 10) + 1) : 1;
        const nextMonthYear = nextMonth !== 1 ? parseInt(year, 10) : (parseInt(year, 10) + 1);
        const nextMonthRes = await getAllDailySchedulesByMonth({ year: nextMonthYear, month: nextMonth });
        const nextDayVersions = nextMonthRes?.data?.find((el) => el?.day === 1)?.versions;
        const previousDayVersions = res?.data?.find((el) => el?.day === (parseInt(day, 10) - 1))?.versions;
        setPreviousDayLatestVersion(previousDayVersions?.[previousDayVersions?.length - 1]);
        setNextDayLatestVersion(nextDayVersions?.[nextDayVersions?.length - 1]);
      }
    } catch (e) {
      console.log(e);
      setDataSource(null);
    } finally {
      setLoading(false);
    }
  }

  const getDailyPlanContent = async () => {
    try {
      setLoading(true);
      const res = await getDailyScheduleContent({ year, month, day, version });
      setDataSource(res?.data);
      let earliestHour;
      let latestHour;
      res?.data?.controllers?.forEach((el) => {
        if (!earliestHour && !latestHour) {
          earliestHour = el?.shift?.timeFrom;
          latestHour = el?.shift?.timeTo;
        } else if (!!earliestHour && !!latestHour) {
          if (el?.shift?.timeFrom < earliestHour) earliestHour = el?.shift?.timeFrom;
          if (el?.shift?.timeTo > latestHour && el?.shift?.timeTo < 10) latestHour = el?.shift?.timeTo;
          if (el?.shift?.timeTo < el?.shift?.timeFrom) latestHour = el?.shift?.timeTo;
        }
      });
      const intervalFromHours = parseInt(earliestHour?.split(':')?.[0], 10);
      const intervalToHours = parseInt(latestHour?.split(':'?.[0]), 10) + 1;
      setInterval([intervalFromHours, intervalToHours]);
    } catch (e) {
      console.log(e);
      setDataSource(null);
    } finally {
      setLoading(false);
    }
  };

  const saveChangesToCurrentVersion = async () => {
    setLoading(true);
    try {
      const currentVersionScheduleId = versions?.find((el) => el?.value === parseInt(version, 10))?.versionId;
      await saveChanges({ dailyScheduleId: currentVersionScheduleId, userId: loggedUserId, changes: modifiedData?.[modifiedData?.length - 1] });
      message.success(t('dailySchedule.saveChangesSuccess'));
    } catch (e) {
      console.log(e);
      message.error(e?.data ? e?.data : t('dailySchedule.saveChangesFail'));
    } finally {
      setLoading(false);
    }
  };

  const saveChangesToNewVersion = async (goToNextVersion) => {
    setLoading(true);
    try {
      await saveChangesAsNewVersion({ dailyScheduleId: currentVersionScheduleId, userId: loggedUserId, changes: modifiedData?.[modifiedData?.length - 1] });
      message.success(t('dailySchedule.saveChangesSuccess'));
      await getDailyPlanVersions();
      await getDailyPlanContent();
      if (goToNextVersion) {
        goToNextVersion();
      }
    } catch (e) {
      console.log(e);
      message.error(e?.data ? e?.data : t('dailySchedule.saveChangesFail'));
    } finally {
      setLoading(false);
    }
  }

  const sendToReview = async () => {
    setLoading(true);
    try {
      await sendScheduleToReview({ scheduleId: currentVersionScheduleId, userId: loggedUserId });
      message.success(t('dailySchedule.sendToReviewSuccess'));
    } catch (e) {
      console.log(e);
      message.error(t('dailySchedule.sendToReviewFail'));
    } finally {
      setLoading(false);
    }
  };

  const approveSchedule = async () => {
    setLoading(true);
    try {
      await approveDailySchedule({ scheduleId: currentVersionScheduleId, userId: loggedUserId });
      message.success(t('dailySchedule.approveScheduleSuccess'));
    } catch (e) {
      console.log(e);
      message.error(t('dailySchedule.approveScheduleFail'));
    } finally {
      setLoading(false);
    }
  }

  const rejectSchedule = async () => {
    setLoading(true);
    try {
      await rejectDailySchedule({ scheduleId: currentVersionScheduleId, userId: loggedUserId });
      message.success(t('dailySchedule.rejectScheduleSuccess'));
    } catch (e) {
      console.log(e);
      message.error(t('dailySchedule.rejectScheduleFail'));
    } finally {
      setLoading(false);
    }
  }

  return {
    loading,
    dataSource,
    versions,
    getDailyPlanContent,
    getDailyPlanVersions,
    saveChangesToCurrentVersion,
    saveChangesToNewVersion,
    sendToReview,
    approveSchedule,
    rejectSchedule,
    interval,
    previousDayLatestVersion,
    nextDayLatestVersion,
    nextDayLatestActiveVersion,
    setPreviousDayLatestVersion,
    setNextDayLatestVersion
  }
}

export default useDetailedDailySchedule;
