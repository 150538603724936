import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState, useLayoutEffect } from 'react';
import { ReactSVG } from 'react-svg';
import {
  MainWrapper,
  SheetHeadingContainer,
  SheetRow,
  EmployeeNameCell,
  SheetBodyContainer,
  VersionCell,
  EmployeeTimelineCell,
  TwoHoursCellContainer,
  EmptyNameCell,
  EmptyTimelineCell,
  GenerateBox,
  HourTick,
  HourTicksCell,
  HourLabelsCell,
  HourCaptionWrapper,
  LocationMark,
  HourCaption,
  FirstHourCaption,
  VerticalBar,
  VerticalBarsContainer
} from './DailySheet.style';
import ShiftContainer from './shiftContainer/ShiftContainer';
import notFoundIcon from '../../../assets/images/icons/file-not-found.svg';
import DailySheetTooltip from './tooltip/DailySheetTooltip';

const DailySheet = (props) => {
  const [hourCellWidth, setHourCellWidth] = useState();
  const [timeLineGapWidth, setTimeLineGapWidth] = useState();
  const [windowSize, setWindowSize] = useState();
  const [numberOfEmptyRows, setNumberOfEmptyRows] = useState(0);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipContent, setTooltipContent] = useState(null);

  const { dataSource, version, modifiedData, setModifiedData, loading, interval, editModeOn, latestVersionIsSelected } = props;
  const { t } = useTranslation();

  const hourCellRef = useRef();
  const timeLineRef = useRef();
  const mainSheetRef = useRef();

  const renderSheetRows = () => {
    const rowsArray = [];
    dataSource?.controllers?.forEach((el, index) => {
      rowsArray.push(
        <SheetRow>
          <EmployeeNameCell isShiftManager={el?.role !== 'NONE'}>
            {el?.firstName + ' ' + el?.lastName}
            <LocationMark>{el?.shift?.scheduledAtcu?.abbreviation}</LocationMark>
          </EmployeeNameCell>
          <EmployeeTimelineCell ref={index === 0 ? timeLineRef : null}>
            <ShiftContainer
              obligations={el?.obligations}
              controllerId={el?.id}
              timeFrom={el?.shift?.timeFrom}
              timeTo={el?.shift?.timeTo}
              hourCellWidth={hourCellWidth}
              timeLineWidth={timeLineGapWidth}
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              setTooltipVisible={setTooltipVisible}
              setTooltipContent={setTooltipContent}
              atcu={el?.shift?.scheduledAtcu?.identificator}
              interval={interval}
              dataSource={dataSource}
              editModeOn={editModeOn}
            />
          </EmployeeTimelineCell>
        </SheetRow>
      );
    })
    return (<>{rowsArray}</>);
  };

  const renderEmptySheetRows = () => {
    const emptyRowsArr = [];
    for (let i = 0; i < numberOfEmptyRows; i += 1) {
      emptyRowsArr.push(
        <SheetRow>
          <EmptyNameCell />
          <EmptyTimelineCell />
        </SheetRow>
      )
    }
    return emptyRowsArr;
  }

  useEffect(() => {
    setHourCellWidth(hourCellRef?.current?.offsetWidth);
    setTimeLineGapWidth(timeLineRef?.current?.clientWidth / 21);
    setNumberOfEmptyRows(Math.round(mainSheetRef?.current?.offsetHeight / 45) - 2);
  }, [dataSource, windowSize]);

  const renderVerticalBars = () => {
    const elementsArray = [];
    const numberOfHours = 24 - interval?.[0] + interval?.[1];
    if (!interval) {
      for (let i = 0; i <= 23; i += 1) {
        elementsArray.push(<VerticalBar hourCellWidth={hourCellWidth} value={i} />)
      }
    } else if (interval) {
      for (let i = interval?.[0]; i <= ((interval?.[0] + numberOfHours) - 1); i += 1) {
        elementsArray.push(<VerticalBar hourCellWidth={hourCellWidth} value={i} />)
      }
    }
    return (
      <VerticalBarsContainer>
        {elementsArray}
      </VerticalBarsContainer>
    )
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', () => {
      setWindowSize(window.innerWidth);
    });
    document.addEventListener('contextmenu', (event) => event.preventDefault());
  }, []);

  const renderHeaderRows = () => {
    const ticksArray = [];
    const hourCaptionsArr = [];

    const numberOfHours = 24 - interval?.[0] + interval?.[1];

    if (!interval) {
      for (let i = 0; i <= 23; i += 1) {
        ticksArray.push(<HourTick ref={i === 0 ? hourCellRef : null} />);
        hourCaptionsArr.push(
          <HourCaptionWrapper>
            {
                i === 0 && (
                  <FirstHourCaption>00</FirstHourCaption>
                )
              }
            <HourCaption>
              {i < 23 ? ((i + 1)?.toString()?.length === 1 ? ('0' + (i + 1).toString()) : (i + 1)) : '00'}
            </HourCaption>
          </HourCaptionWrapper>
        );
      }
    } else if (interval) {
      for (let i = interval?.[0]; i <= ((interval?.[0] + numberOfHours) - 1); i += 1) {
        ticksArray.push(<HourTick ref={i === interval?.[0] ? hourCellRef : null} />);
        hourCaptionsArr.push(
          <HourCaptionWrapper>
            {
              i === interval?.[0] && (
                <FirstHourCaption>{interval?.[0]?.toString()?.length === 1 ? ('0' + interval?.[0]?.toString()) : interval?.[0]}</FirstHourCaption>
              )
            }
            <HourCaption>
              {i < 23 ? ((i + 1)?.toString()?.length === 1 ? ('0' + (i + 1).toString()) : (i + 1)) : ((i - 23)?.toString()?.length === 1 ? ('0' + (i - 23)) : (i - 23))}
            </HourCaption>
          </HourCaptionWrapper>
        );
      }
    }

    return (
      <>
        <SheetRow>
          <VersionCell>
            ver.
            {version}
          </VersionCell>
          <TwoHoursCellContainer>
            <HourLabelsCell>
              {hourCaptionsArr}
            </HourLabelsCell>
            <HourTicksCell>
              {ticksArray}
            </HourTicksCell>
          </TwoHoursCellContainer>
        </SheetRow>
      </>
    );
  };

  return (
    <MainWrapper id="mainWrapper" ref={mainSheetRef}>
      <SheetHeadingContainer>
        {renderHeaderRows()}
      </SheetHeadingContainer>
      <SheetBodyContainer>
        {(!!dataSource && (!editModeOn || (editModeOn && latestVersionIsSelected))) && renderVerticalBars()}
        {(!!dataSource && (!editModeOn || (editModeOn && latestVersionIsSelected))) && renderSheetRows()}
        {(loading || !dataSource || (!latestVersionIsSelected && editModeOn)) && renderEmptySheetRows()}
      </SheetBodyContainer>
      {(!loading && (props?.notGenerated || props?.nonExistingVersion || (!latestVersionIsSelected && editModeOn))) && (
        <GenerateBox>
          <ReactSVG src={notFoundIcon} style={{ width: '100px', margin: '0 auto' }} />
          {props?.notGenerated ? t('dailySchedule.noVersionGenerated') : (props?.nonExistingVersion ? t('dailySchedule.versionDoesntExist') : t('dailySchedule.onlyLatestVersionIsEditable'))}
        </GenerateBox>
      )}
      <DailySheetTooltip visible={tooltipVisible} content={tooltipContent} sheetRef={mainSheetRef} />
    </MainWrapper>
  );
};

export default DailySheet;
